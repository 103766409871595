import React from "react";
import { IArtistColumn } from "../../../models/grid/artists/iArtistColumn";
import EditableColArtist from "./editableColArtist";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { deleteDraftArtistGridRow, increaseDraftArtistGridRowColumns, shrinkDraftArtistGridRowColumns } from "../../../store/slices/artistsGridSlice";

interface Props{
    columns: IArtistColumn[],
    rowOrder: number
}

export default function EditableArtistRow({rowOrder, columns} : Props){
    const dispatch = useAppDispatch();
    const draftRowsLengt = useAppSelector(state => state.artistsGrid.rows.length);

    const handleColumnsInRow = (event: React.ChangeEvent<HTMLSelectElement>) =>{
        if(+event.target.value < columns.length){
            dispatch(shrinkDraftArtistGridRowColumns({rowOrder: rowOrder, columns: [...columns].splice(0, +event.target.value)}));
        }
        else{
            dispatch(increaseDraftArtistGridRowColumns({rowOrder: rowOrder, increaseNumber: +event.target.value}));
        }
    }
    
    const handleDeleteRow = () =>{
        dispatch(deleteDraftArtistGridRow({rowOrder: rowOrder}));
    }

    return(
        <div className="row d-flex flex-fill">
            <div className="row">
                <div className="col">
                    <label className="me-2">количество ячеек в строке</label>
                    <select value={columns.length} onChange={handleColumnsInRow}>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </select>
                    {
                        draftRowsLengt > 1 && <button className="btn btn-danger btn-small ms-5 mb-3" onClick={handleDeleteRow}>удалить строку</button>
                    }
                </div>
            </div>
            {
                [...columns]
                .sort((x, y) => x.order - y.order)
                .map((col) => {
                    return <EditableColArtist key={col.order} rowOrder={rowOrder} column={col} />
                })
            }
        </div>
    )
}