import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import ColLoginButton from "./colLoginButton";
import ColSearch from "./colSearch";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { setEditMode } from "../../store/slices/adminSlice";

export default function SideMenu(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {user, admin} = useAppSelector(state => state);
    const dispatch = useAppDispatch();

    const checkBoxHandler = () =>{
        dispatch(setEditMode(!admin.editMode));
    }

    return(
        <div className="ms-1 mb-5 pt-3 ms-2">
            <button className="btn btn-sm btn-dark border border-light rounded-pill position-fixed" type="button" onClick={handleShow}>
                <span className="navbar-toggler-icon ps-2 pe-2"></span>
            </button>
            <Offcanvas show={show} onHide={handleClose} className="text-bg-dark">
                <Offcanvas.Header closeButton className="justify-content-end btn-close-white"></Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-column">
                        {
                            <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                                {
                                    user.isSa &&
                                    <div className="row mt-3">
                                        <div className="col">
                                            <div className="form-check form-switch">
                                                <input 
                                                    className="form-check-input" 
                                                    checked={admin.editMode}
                                                    onChange={checkBoxHandler} 
                                                    type="checkbox" 
                                                    role="switch" 
                                                />
                                                <label>edit mode switcher</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <ColSearch isInSideMenu={true}/>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <ColLoginButton isInSideMenu={true} width={100} marginBottom={1} onClickEvent={handleClose}/>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <div className="col d-flex justify-content-center">
                                <div className="h4">
                                    <Link className="nav-link" to={"home"}onClick={handleClose}>ГЛАВНАЯ</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <div className="col d-flex justify-content-center">
                                <div className="h4">
                                    <Link className="nav-link" to={"about"} onClick={handleClose}>О НАС</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <div className="col d-flex justify-content-center">
                                <div className="h4">
                                    <Link className="nav-link" to={"videos"} onClick={handleClose}>ВИДЕО</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <div className="col d-flex justify-content-center">
                                <div className="h4">
                                    <Link className="nav-link" to={"artists"} onClick={handleClose}>АРТИСТЫ</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 border-bottom border-secondary border-opacity-50">
                            <div className="col d-flex justify-content-center">
                                <div className="h4">
                                    <Link className="nav-link" to={"events"} onClick={handleClose}>СОБЫТИЯ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}