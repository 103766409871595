import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "../../models/iUserInfo";


interface adminState{
    editMode: boolean
}

const initialState: adminState = {
    editMode: false
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        setEditMode(state, action: PayloadAction<boolean>){
            state.editMode = action.payload;
        }
    }
})

export default adminSlice.reducer;
export const { setEditMode } = adminSlice.actions;