import React from "react";

export default function Footer(){

    return(
        <footer className="footer bg-black border-top border-secondary border-opacity-50">
            <div className="container m-1">
                <div className="row">
                    <div className="col align-items-end">
                        <p className="mb-0 text-white">@2023 МУЗЫКАВМЕСТЕ</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}