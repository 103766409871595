import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Props{
    base64: string,
    to: string
}

export default function ImageLinkWrapper({ base64, to }: Props){

    const [opacity, setOpacity] = useState<string>("opacity-100");

    const onMouseEnterHandler = () => {
        setOpacity("opacity-75")
    }
    const onMouseLeaveHandler = () => {
        setOpacity("opacity-100")
    }

    return(
        <div className="d-flex flex-fill p-2">
            <Link to={to} className={"d-flex flex-fill " + opacity} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                <img src={base64} alt="" className="img-fluid"/>
            </Link>
        </div>
    )
}