import React from "react";
import brand_icon from '../../images/brand.png';
import { Link } from "react-router-dom";

export default function ColBrandIcon(){

    return(
        <div className="col-sm-1 w-auto">
            <Link to='/home'>
                <img
                    src={brand_icon}
                    width="80"
                    height="80"
                    className="d-inline-block align-top"
                    alt=""
                />
            </Link>
        </div>
    );
}