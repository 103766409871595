import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props{
    link: string,
    img: string
}

export default function IframeModal({link, img}: Props){

    const [show, setShow] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<string>("opacity-100");
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

    const onMouseEnterHandler = () => {
        setOpacity("opacity-75")
    }
    const onMouseLeaveHandler = () => {
        setOpacity("opacity-100")
    }
    return(
        <>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton closeVariant="white" className="bg-dark border-dark"/>
                <Modal.Body className="bg-dark">
                    <div className="h-100 d-flex flex-column border border-secondary border-opacity-50">
                        <iframe
                            className="video ps-2 pe-2 pt-2"
                            src={link}
                            allow="accelerometer; encrypted-media; gyroscope;"
                            allowFullScreen
                        ></iframe>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="d-flex flex-fill p-2">
                <Link to="#" onClick={handleOpen} className={"d-flex justify-content-center flex-fill " + opacity} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                    <img src={img} className="img-fluid"/>
                </Link>
            </div>
        </>
    );
}