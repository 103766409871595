import React from "react";
import GridWrapper from "../components/grid/gridWrapper";
import { useGetArtistsGridQuery } from "../api/apiSlice";
import RowTitle from "../components/grid/rowTitle";
import ArtistsList from "../components/grid/artists/artistsList";
import RowWithColumns from "../components/grid/rowWithColumns";
import { useAppSelector } from "../hooks/storeHooks";
import ArtistEdit from "../components/admin/artistsEdit";


export default function Artists(){

    const {data, isLoading} = useGetArtistsGridQuery();
    const admin = useAppSelector(state => state.admin);
    
    if(data){
        return(
            <GridWrapper>
                {
                    admin.editMode && <ArtistEdit data={data}/>
                }
                {
                    admin.editMode == false &&
                    <>
                        <RowTitle title={data.titles[0].name}></RowTitle>
                        {
                            [...data.rows]
                            .sort((x, y) => x.order - y.order)
                            .map(x => {
                                return (<RowWithColumns key={x.id} columns={x.columns}/>)
                            })
                        }
                        <RowTitle title={data.titles[1].name}></RowTitle>
                        <ArtistsList artistsList={data.artistList} />
                    </>
                }
            </GridWrapper>
        );
    }
    
    return <div>Loading...</div>
}