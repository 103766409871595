import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { IMemberInput } from '../models/iMemberInput';
import { ILoginInput } from '../models/iLoginInput';
import { IUserInfo } from '../models/iUserInfo';
import { IArtist } from '../models/iArtist';
import { IArtistsGrid } from '../models/grid/artists/iArtistsGrid';
import { IEventGrid } from '../models/grid/events/iEventGrid';
import { IVideosGrid } from '../models/grid/videos/iVideosGrid';
import { IHomeGrid } from '../models/grid/home/iHomeGrid';
import { IAboutGrid } from '../models/grid/about/iAboutGrid';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api', 
        credentials: "include"
    }),
    tagTypes: ["AboutGrid", "ArtistsGrid"],
    endpoints: (builder) => ({

        postMemberForm: builder.mutation<any, IMemberInput>({
            query: (memberInput) => ({
                method: 'POST',
                url: '/Member/AddMember',
                body: memberInput
            })
        }),

        postLogin: builder.mutation<any, ILoginInput>({
            query: (loginInput) =>({
                method: 'POST',
                url: '/Account/Login',
                body: loginInput
            })
        }),

        getUserInfo: builder.query<IUserInfo | null, void>({
            query: () => ({
                method: 'GET',
                url: '/Account/GetSessionUser'
            })
        }),

        getArtistsGrid: builder.query<IArtistsGrid, void>({
            query: () => ({
                method: 'GET',
                url: '/ArtistGrid/GetGrid'
            }),
            providesTags: ["ArtistsGrid"]
        }),

        getArtistById: builder.query<IArtist, string>({
            query: (id) => ({
                method: 'GET',
                url: `/ArtistGrid/GetById/${id}`
            })
        }),

        getEvents: builder.query<IEventGrid[], void>({
            query: (id) => ({
                method: 'GET',
                url: `/Event/GetEvents`
            })
        }),
        getVideosGrid: builder.query<IVideosGrid, void>({
            query: () => ({
                method: 'GET',
                url: '/VideosGrid/videosGrid'
            })
        }),
        getHomeGrid: builder.query<IHomeGrid, void>({
            query: () => ({
                method: 'GET',
                url: '/homeGrid/homeGrid'
            })
        }),
        getAboutGrid: builder.query<IAboutGrid, void>({
            query: () => ({
                method: 'GET',
                url: '/AboutGrid/AboutGrid'
            }),
            providesTags: ["AboutGrid"]
        }),
        updateAboutGrid: builder.mutation<IAboutGrid, {id: number, aboutGrid: IAboutGrid}>({
            query: ({id, aboutGrid}) =>({
                method: 'PUT',
                url: `/AboutGrid/UpdateAboutGrid/${id}`,
                body: aboutGrid
            }),
            invalidatesTags: ["AboutGrid"]
        }),
        updateArtistsGrid: builder.mutation<IArtistsGrid, {id: number, grid: IArtistsGrid}>({
            query: ({id, grid}) =>({
                method: 'PUT',
                url: `/ArtistGrid/Update/?id=${id}`,
                body: grid,
            }),
            invalidatesTags: ["ArtistsGrid"]
        })
    })
})

export const { 
    usePostMemberFormMutation,
    usePostLoginMutation,
    useLazyGetUserInfoQuery,
    useGetUserInfoQuery,
    useGetArtistsGridQuery,
    useGetArtistByIdQuery,
    useGetEventsQuery,
    useGetVideosGridQuery,
    useGetHomeGridQuery,
    useGetAboutGridQuery,
    useLazyGetArtistByIdQuery,
    useUpdateAboutGridMutation,
    useUpdateArtistsGridMutation
} = apiSlice;