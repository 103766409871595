import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import userSlice from "./slices/userSlice";
import adminSlice from "./slices/adminSlice";
import artistsGridSlice from "./slices/artistsGridSlice";
import artistsListSlice from "./slices/artistsListSlice";

const combineReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    user: userSlice,
    admin: adminSlice,
    artistsGrid: artistsGridSlice,
    artistsList: artistsListSlice
});

const store = configureStore({
    reducer:combineReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(apiSlice.middleware)
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;