import React, { useEffect } from "react";
import GridWrapper from "../grid/gridWrapper";
import { IArtistsGrid } from "../../models/grid/artists/iArtistsGrid";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { addNewRowToDraftArtistGrid, setDraftArtistsGridRows } from "../../store/slices/artistsGridSlice";
import RowTitle from "../grid/rowTitle";
import EditableArtistRow from "./editableGrid/editableArtistRow";
import { setArtistsList } from "../../store/slices/artistsListSlice";
import NewArtistModal from "../modal/newArtistModal";
import { useUpdateArtistsGridMutation } from "../../api/apiSlice";

interface Props{
    data: IArtistsGrid
}

export default function ArtistEdit({data}: Props){
    const dispatch = useAppDispatch();
    const draftData = useAppSelector(state => state.artistsGrid);
    const[save, {isSuccess, isError, isUninitialized, reset}] = useUpdateArtistsGridMutation();

    const onAddClickHandler = () =>{
        dispatch(addNewRowToDraftArtistGrid());
    } 

    const handleSaveClick = () =>{
        let saveGrid: IArtistsGrid = {
            id: data.id,
            artistList: [],
            rows: draftData.rows,
            titles: []
        }
        save({id: data.id, grid: saveGrid});
    }

    useEffect(() =>{
        if(isSuccess){
            alert("страница артистов обновлена");
            reset();
        }
        else if(isError){
            alert("произошла ошибка обновления страницы артистов. попробуйте снова");
        }
        else if(!isSuccess && !isError && isUninitialized){
            dispatch(setDraftArtistsGridRows(data.rows));
            dispatch(setArtistsList(data.artistList));
        }
    }, [data, isSuccess, isError]);
    
    return (
        <GridWrapper>
                <div className="row mt-2">
                    <div className="col-2">
                        <button className="btn btn-success" type="button" onClick={handleSaveClick}>сохранить изменения</button>
                    </div>
                    <div className="col-2">
                        <NewArtistModal />
                    </div>
                </div>
                <RowTitle title={data.titles[0].name}></RowTitle>
                {
                    [...draftData.rows]
                    .sort((x, y) => x.order - y.order)
                    .map(x => {
                        return (<EditableArtistRow key={x.order} rowOrder={x.order} columns={x.columns}/>)
                    })
                }
                <div className="row mt-3 mb-3">
                    {
                        draftData.rows.length < 6 && 
                        <button className="btn btn-success" type="button" onClick={onAddClickHandler}>Add</button>
                    }
                </div>
        </GridWrapper>
    )
}