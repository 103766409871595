import React from "react";
import GridWrapper from "../components/grid/gridWrapper";
import { useGetAboutGridQuery } from "../api/apiSlice";
import VideoWrapper from "../components/grid/videoWrapper";
import { useAppSelector } from "../hooks/storeHooks";
import AboutEdit from "../components/admin/aboutEdit";

export default function About(){

    const {data} = useGetAboutGridQuery();
    const admin = useAppSelector(state => state.admin);

    if(data){
        return(
            <GridWrapper>
                {
                    admin.editMode && <AboutEdit draftAboutState={data}/>
                }
                {
                    admin.editMode == false &&
                    <>
                        <div className="write-line flex-row text-milk">
                            <div className="col fs-4 mt-5">{data.text}</div>
                        </div>
                        <div className="ms-1 me-1 row mt-4 mb-3">
                            <VideoWrapper link={data.videoLink} />
                        </div>
                    </>
                }
            </GridWrapper>
        )
    }

    return <div>Loading...</div>
}