import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { IArtistNew } from "../../models/iArtistNew";

export default function NewArtistModal(){
    const [show, setShow] = useState<boolean>(false);
    const {register, handleSubmit, reset} = useForm<IArtistNew>({
        defaultValues: {
            artistProfileId: 0
        }
    });
    const submit: SubmitHandler<IArtistNew> = data =>{
    }

    const handleClose = () => setShow(false);
    const handleClick = () => setShow(true);

    return(
        <>
            <button className="btn btn-success" type="button" onClick={handleClick}>добавить артиста</button>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton/>
                <Modal.Body>
                    <form onSubmit={handleSubmit(submit)}>
                        <input type="text" placeholder="фио в формате Пупкин Ф.С." className="w-100 mb-5" {...register("artistName")}/>
                        <input type="text" placeholder="Страна, город проживания" className="w-100 mb-5"{...register("artistLocation")}/>
                        <div className="d-flex mb-5">
                            <label className="me-3">фото артиста</label>
                            <input type="file" accept="image/png, image/jpeg" {...register("artistImage")}/>
                        </div>
                        <textarea placeholder="описание артиста" className="w-100 mb-5" {...register("artistDiscription")}/>
                        <button type="submit" className="btn btn-success">создать</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}