import React from "react";

interface Props{
    title: string
}

export default function RowTitle({ title }: Props){

    return(
        <div className="row">
            <div className="col mb-3 pt-2 pb-2 border-bottom border-secondary border-opacity-50">
                <h2>{title}</h2>
            </div>
        </div>
    )
}