import React, { useEffect, useState } from "react";
import { IArtistColumn } from "../../../models/grid/artists/iArtistColumn";
import ImageLinkWrapper from "../../grid/imageLinkWrapper";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { fillChangeDraftArtistGridColumn, sizeChangeDraftArtistGridColumn, updateDraftArtistGridColumn } from "../../../store/slices/artistsGridSlice";
import { useLazyGetArtistByIdQuery } from "../../../api/apiSlice";

interface Props{
    column: IArtistColumn,
    rowOrder: number
}

export default function EditableColArtist({rowOrder, column}: Props){
    const dispatch = useAppDispatch();
    const artistsList = useAppSelector(state => state.artistsList.list);
    const [triggerArtistById, resultArtistById] = useLazyGetArtistByIdQuery();
    const [triggeredArtistId, setTriggeredArtistId] = useState<number>(0);

    const whiteSpace = " ";

    const colSizeClass = column?.size ? "col-lg-" + column.size : "col";
    const flexFillClass = column.fill ? "flex-fill" : "";

    const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) =>{
        dispatch(sizeChangeDraftArtistGridColumn({rowOrder: rowOrder, columnOrder: column.order, size: +event.target.value}));
    }
    const handleFillChange = (event: React.ChangeEvent<HTMLSelectElement>) =>{
        dispatch(fillChangeDraftArtistGridColumn({rowOrder: rowOrder, columnOrder: column.order, fill: JSON.parse(event.target.value)}));
    }

    const hangleArtistChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTriggeredArtistId(+event.target.value);
        triggerArtistById(event.target.value);
    }

    useEffect(() =>{
        if(triggeredArtistId != 0)console.log('useEffect');
        if (resultArtistById.isSuccess){
            if (resultArtistById.data){

                let artistColumn: IArtistColumn = {
                    artistImage: resultArtistById.data.artistImage,
                    artistLink: `artists/${triggeredArtistId}`,
                    location: resultArtistById.data.artistLocation,
                    artistName: resultArtistById.data.artistName,
                    artistProfileId: triggeredArtistId,
    
                    fill: column.fill,
                    id: column.id,
                    order: column.order,
                    size: column.size
                };
                dispatch(updateDraftArtistGridColumn({rowOrder: rowOrder, columnOrder: column.order, newColumnState: artistColumn}));
            }
            else
                alert("не удалось загрузить артиста");
        }

        if (resultArtistById.isError){
            alert("произошла ошибка. попробуейте еще раз");
        }
    }, [resultArtistById.fulfilledTimeStamp])

    return(
        <div className={colSizeClass + whiteSpace + flexFillClass + " mb-3 position-relative"}>
            <div className="position-absolute col">
                <select value={column.size} onChange={handleSizeChange}>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                </select>
                <select value={String(column.fill)} onChange={handleFillChange}>
                    <option value={'true'}>true</option>
                    <option value={'false'}>false</option>
                </select>
                <select onChange={hangleArtistChange}>
                    <option>--select artist--</option>
                    {
                        artistsList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                    }
                </select>
            </div>
            <div className="h-100 d-flex flex-column border border-secondary border-opacity-50">
                <ImageLinkWrapper to={column.artistLink} base64={column.artistImage} />
                <div className="mt-4 ps-2 pe-2 pb-2">
                    <p className="h5 mt-1 mb-0">
                        <Link className="link-secondary text-decoration-none" to={column.artistLink}>
                            {column.artistName}
                        </Link>
                    </p>
                    <span className="fs-6 fw-lighter">{column.location}</span>
                </div>
            </div>
        </div>
    )
}