import React, { useEffect } from "react";
import { IAboutGrid } from "../../models/grid/about/iAboutGrid";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUpdateAboutGridMutation } from "../../api/apiSlice";

interface Props{
    draftAboutState: IAboutGrid
}

export default function AboutEdit({draftAboutState}: Props){

    const { register, handleSubmit } = useForm<IAboutGrid>({
        defaultValues:{
            id: draftAboutState.id,
            text: draftAboutState.text,
            videoLink: draftAboutState.videoLink
        }
    });
    const [update, {isSuccess, isError}] = useUpdateAboutGridMutation();

    const submit: SubmitHandler<IAboutGrid> = async data =>{
        update({id: data.id, aboutGrid: data});
    }

    useEffect(() => {
        if(isSuccess){
            alert("Обновлена страница о нас");
        }
        if(isError){
            alert("Ошибка при обновлении страницы о нас. Попробуйте снова.");
        }
    }, [isSuccess, isError])
    
    return(
        <div className="row">
            <form className="col" onSubmit={handleSubmit(submit)}>
                <div className="mt-3">
                    <label>основной текст "о нас"</label>
                    <textarea className="form-control" {...register('text')} />
                </div>
                <div className="mt-3">
                    <label>ссылка должна выглядить так "https://www.youtube.com/embed/o5FD7rzKJow" где обязательно embed и дальше код видео</label>
                    <input className="form-control" {...register('videoLink')}/>
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" type="submit"/>
                </div>
            </form>
        </div>
    )
}