import React from "react";
import { Link } from "react-router-dom";

export default function ColNavItemLinks(){

    return(
        <div className="col-sm-3 col-lg-5">
            <ul className="navbar-nav d-none d-xl-flex justify-content-center">
                <li className="nav-item"><Link className="nav-link" to={"home"}>ГЛАВНАЯ</Link></li>
                <li className="nav-item"><Link className="nav-link" to={"about"}>О НАС</Link></li>
                <li className="nav-item"><Link className="nav-link" to={"videos"}>ВИДЕО</Link></li>
                <li className="nav-item"><Link className="nav-link" to={"artists"}>АРТИСТЫ</Link></li>
                <li className="nav-item"><Link className="nav-link" to={"events"}>СОБЫТИЯ</Link></li>
            </ul>
        </div>
    );
}