import React from "react";
import { Carousel } from "react-bootstrap";
import GridWrapper from "../components/grid/gridWrapper";
import IframeModal from "../components/modal/iframeModal";
import { useGetHomeGridQuery } from "../api/apiSlice";
import HomeCaruselItem from "../components/homeCaruselItem";
import { ICaruselItem } from "../models/grid/home/iCaruselItem";
import RowWithColumns from "../components/grid/rowWithColumns";

export default function Home(){

    const {data} = useGetHomeGridQuery();

    if(data){
        return(
            <GridWrapper>
                <Carousel className="mt-2 mb-2 border border-secondary border-opacity-50">
                        {
                            data.homeCaruselItemDtos.map((x, index) => 
                                <Carousel.Item key={index}>
                                    <IframeModal img={x.image} link={x.link} />
                                    <Carousel.Caption className="d-flex justify-content-center">
                                        <div className="h4 text-bg-dark bg-dark bg-opacity-50 d-inline-flex ps-2 pe-2">
                                            {x.description}
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        }
                </Carousel>
                {
                    [...data.videoRowDtos]
                    .sort((x, y) => x.order - y.order)
                    .map((x, index) => {
                        return (<RowWithColumns key={index} columns={x.columns}/>)
                    })
                }
            </GridWrapper>
        )
    }

    return <div>Loading...</div>
}