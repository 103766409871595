import React from "react";
import ImageWrapper from "../imageWrapper";
import { Link } from "react-router-dom";
import { IArtistColumn } from "../../../models/grid/artists/iArtistColumn";
import ImageLinkWrapper from "../imageLinkWrapper";

interface Props{
    column: IArtistColumn
}

export default function ColArtist({ column }: Props){
    const whiteSpace = " ";

    const colSizeClass = column?.size ? "col-lg-" + column.size : "col";
    const flexFillClass = column.fill ? "flex-fill" : "";

    console.log(column);

    return(
        <div className={colSizeClass + whiteSpace + flexFillClass + " mb-3"}>
            <div className="h-100 d-flex flex-column border border-secondary border-opacity-50">
                <ImageLinkWrapper to={column.artistLink} base64={column.artistImage} />
                <div className="mt-4 ps-2 pe-2 pb-2">
                    <p className="h5 mt-1 mb-0">
                        <Link className="link-secondary text-decoration-none" to={column.artistLink}>
                            {column.artistName}
                        </Link>
                    </p>
                    <span className="fs-6 fw-lighter">{column.location}</span>
                </div>
            </div>
        </div>
    )
}