import React from "react";
import GridWrapper from "../components/grid/gridWrapper";
import { useGetEventsQuery } from "../api/apiSlice";
import RowTitle from "../components/grid/rowTitle";
import { mounthToString } from "../utils/date";

export default function Events() {

    const { data } = useGetEventsQuery();

    if (data) {
        return (
            <GridWrapper>
                {/* <RowTitle title={data.title.name} /> */}

                <div className="row h-25 mt-3 mb-3">
                    <div className="col d-flex justify-content-center">
                        <div className="h2">События и предстоящие мероприятия.</div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col d-flex justify-content-center">
                        <table className="table-dark table-bordered w-75">
                            <tbody>
                                {[...data[0].events].sort((x, y) => x.order - y.order).map(x => (
                                    <tr key={x.id}>
                                        <td className="fs-4 p-4 fw-bold">
                                           <div className="text-center">{x.eventDate.split('.')[0]}</div>
                                           <div className="text-center">{mounthToString(x.eventDate.split('.')[1])}</div>
                                        </td>
                                        <td className="p-4">
                                            <div className="text-start fs-3 fw-bold">{x.eventTitle}</div>
                                            {/* <div className="text-start fs-5">{x.eventDescription}</div> */}
                                            <div className="text-start fs-5 text-milk">{x.eventLocation}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </GridWrapper>
        )
    }
    return (<div>Loading...</div>);
}