// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control:focus {
    border-color: white;
    box-shadow: inset 0 1px 1px rgba(196, 196, 200, 0.075), 0 0 8px rgba(236, 231, 231, 0.6);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


.img{
    max-width: 100%;
    max-height: 100%;
}

.bg-opacity-50{
    --bs-bg-opacity: .5;
}
.border-opacity-50{
    --bs-border-opacity: 0.5
}

.video {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
}
  
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.link-secondary{
    color: rgba(255, 255, 255, 0.825) !important;
}

.link-secondary:hover{
    color: white !important;
}

.text-milk{
    color: rgba(255, 255, 255, 0.825) !important;
}

.write-line{
    white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wFAAwF;AAC5F;;AAEA;;;0DAG0D,aAAa,EAAE;;;AAGzE;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI;AACJ;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".form-control:focus {\n    border-color: white;\n    box-shadow: inset 0 1px 1px rgba(196, 196, 200, 0.075), 0 0 8px rgba(236, 231, 231, 0.6);\n}\n\ninput[type=\"search\"]::-webkit-search-decoration,\ninput[type=\"search\"]::-webkit-search-cancel-button,\ninput[type=\"search\"]::-webkit-search-results-button,\ninput[type=\"search\"]::-webkit-search-results-decoration { display: none; }\n\n\n.img{\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.bg-opacity-50{\n    --bs-bg-opacity: .5;\n}\n.border-opacity-50{\n    --bs-border-opacity: 0.5\n}\n\n.video {\n    aspect-ratio: 16 / 9;\n    width: 100%;\n    height: 100%;\n}\n\n.video-container {\n    position: relative;\n    padding-bottom: 56.25%;\n}\n  \n.video-container iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n\n.link-secondary{\n    color: rgba(255, 255, 255, 0.825) !important;\n}\n\n.link-secondary:hover{\n    color: white !important;\n}\n\n.text-milk{\n    color: rgba(255, 255, 255, 0.825) !important;\n}\n\n.write-line{\n    white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
