import React from "react";

interface Props{
    link: string
}

export default function VideoWrapper({link}: Props){

    return(
        <div className="h-100 d-flex flex-column border border-secondary border-opacity-50 p-0">
            <iframe
                    className="video p-2"
                    src={link}
                    // allow="accelerometer; encrypted-media; gyroscope;"
                    allowFullScreen
                    >
            </iframe>
        </div>
    )
}