export function mounthToString(mounth: string):string {
    switch(mounth){
        case "01":
            return "ЯНВАРЯ"
        case "02":
            return "ФЕВРАЛЯ"
        case "03":
            return "МАРТА"
        case "04":
            return "АПРЕЛЯ"
        case "05":
            return "МАЯ"
        case "06":
            return "ИЮНЯ"
        case "07":
            return "ИЮЛЯ"
        case "08":
            return "АВГУСТА"
        case "09":
            return "СЕНТЯБРЯ"
        case "10":
            return "ОКТЯБРЯ"
        case "11":
            return "НОЯБРЯ"
        case "12":
            return "ДЕКАБРЯ"
        default:
            return "" 
    }
}