import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import { Provider } from 'react-redux/es/exports';
import store from './store/store';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import Router from './routes';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );