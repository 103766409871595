import React, { useEffect } from "react";
import { ILoginInput } from "../../models/iLoginInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLazyGetUserInfoQuery, usePostLoginMutation } from "../../api/apiSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setUserInfo } from "../../store/slices/userSlice";

export default function LoginForm(){

    const { register, handleSubmit, reset, formState: { isSubmitSuccessful, errors } } = useForm<ILoginInput>();
    const [login, {isError, error, isSuccess} ] = usePostLoginMutation();
    const submit: SubmitHandler<ILoginInput> = async data =>{
        login(data);
    }
    const [trigger, userInfoResult] = useLazyGetUserInfoQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() =>{
        if(isSuccess && isSubmitSuccessful){
            reset({
                email: "",
                password: ""
            });

            if(userInfoResult.isUninitialized){
                trigger();
            }

        }
        if(userInfoResult.isSuccess){
            if(userInfoResult.data){
                dispatch(setUserInfo(userInfoResult.data));
                navigate("/home");
            }
        }
    }, [isSuccess, isSubmitSuccessful, userInfoResult.isSuccess])

    return(
        <div className="row justify-content-center mb-3 mt-2 h-100">
            <form className="col-md-9 col-lg-8" onSubmit={handleSubmit(submit)}>
                <div className="h2 text-center">ВОЙТИ</div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" placeholder="ПОЧТА" { ...register("email", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.email?.message) && <div className="text-danger">{errors?.email?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <input type="password" className="form-control form-control-dark text-bg-dark" placeholder="ПАРОЛЬ" { ...register("password", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.password?.message) && <div className="text-danger">{errors?.password?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" type="submit"/>
                </div>
            </form>
        </div>
    )
}