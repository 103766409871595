import React from "react";

import { Link } from "react-router-dom";
import BecomeMemberForm from "../components/account/becomeMemberForm";

export default function BecomeMember(){

    return (
        <>
            <div className="text-white d-flex h-100 flex-column">
                <div className="row mt-1">
                    <div className="col d-flex justify-content-end mb-2">
                        <div>{"Уже есть аккаунт? ->"}</div>
                        <div className="link ms-1"><Link className="link-light" to={"/login"}>войти</Link></div>
                    </div>
                </div>
                <BecomeMemberForm />
            </div>
        </>
    );
}