import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "../../models/iUserInfo";


interface userInitialState{
    isAuthenticated: boolean,
    userName: string,
    isSa: boolean
}

const initialState: userInitialState = {
    isAuthenticated: false,
    userName: "guest",
    isSa: true
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUserInfo(state, action: PayloadAction<IUserInfo>){
            state.isAuthenticated = action.payload.isAuthenticated;
            state.userName = action.payload.userName;
        }
    }
})

export default userSlice.reducer;
export const { setUserInfo } = userSlice.actions;