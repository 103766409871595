import React from "react";
import { ICol } from "../../models/grid/iCol";
import ColVideo from "./colVideo";
import { IArtistColumn } from "../../models/grid/artists/iArtistColumn";
import ColArtist from "./artists/colArtist";
import { IVideoColumn } from "../../models/grid/videos/iVideoColumn";

interface Props{
    columns: ICol[]
}

export default function RowWithColumns({ columns = []} : Props){

    return(
        <div className="row d-flex flex-fill">
            {
                [...columns]
                .sort((x, y) => x.order - y.order)
                .map((col, index) => {
                    if ('relatedArtists' in col)
                        return <ColVideo key={index} column={col as IVideoColumn} />
                    if ('artistName' in col)
                        return <ColArtist key={index} column={col as IArtistColumn} />
                })
            }
        </div>
    )
}