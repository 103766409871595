import React from "react";

interface Props{
    isInSideMenu?: boolean
}

export default function ColSearch({isInSideMenu = false} : Props){

    const sideMenuClassName = "form-control mb-1 border-0";
    const navbarClassName = "form-control form-control-dark text-bg-dark me-2 border-0";
    const disappearOnSmallScrean = " d-none d-lg-block"

    return(
        <div className={"col d-flex justify-content-end" + (isInSideMenu ? '' : disappearOnSmallScrean)}>
            <form className={"d-flex" + (isInSideMenu ? " w-100" : "")} role="search" onSubmit={(e)=>{e.preventDefault();}}>
                <input 
                    className={isInSideMenu ? sideMenuClassName : navbarClassName}
                    type="search" 
                    placeholder="ПОИСК" 
                    aria-label="Search" 
                />
            </form>
        </div>
    );
}