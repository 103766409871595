import React from "react";
import { IArtistGroup } from "../../../models/grid/artists/iArtistGroup";
import ArtistsListColumn from "./artistsListColumn";

interface Props{
    artistsList: IArtistGroup[]
}

export default function ArtistsList({artistsList}: Props){
    const reminder = artistsList.length % 2;
    const middlePoint = reminder == 0 ? artistsList.length / 2 : (artistsList.length - 1) / 2;

    return(
        <>
            <div className="row pb-4 d-flex flex-fill border border-secondary border-opacity-50 mb-3">
                {
                    artistsList.length > 4 
                    ? 
                    <>
                        <ArtistsListColumn dictionary={artistsList.slice(0, middlePoint)} />
                        <ArtistsListColumn dictionary={artistsList.slice(middlePoint)} />
                    </>
                    :
                    <ArtistsListColumn dictionary={artistsList} />
                }
            </div>
        </>
    )
}