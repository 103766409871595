import React from "react";

interface Props{
    children: React.ReactNode
}

export default function GridWrapper({children}: Props){

    return(
        <div className="text-white d-flex flex-column h-100">
            {children}
        </div>
    )
}