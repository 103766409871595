import React from "react";
import GridWrapper from "../components/grid/gridWrapper";
import { useGetVideosGridQuery } from "../api/apiSlice";
import RowTitle from "../components/grid/rowTitle";
import RowWithColumns from "../components/grid/rowWithColumns";

export default function Videos(){
    const {data} = useGetVideosGridQuery();
    if(data){
        [...data.titles].sort((x, y) => x.order - y.order);
        
        return(
            <GridWrapper>
                <RowTitle title={data.titles[0].name}></RowTitle>
                {
                    [...data.rows]
                    .sort((x, y) => x.order - y.order)
                    .map(x => {
                        return (<RowWithColumns key={x.id} columns={x.columns}/>)
                    })
                }
            </GridWrapper>
        );
    }
    return <div>Loading...</div>
}