import React from "react";
import LoginForm from "../components/account/loginForm";

export default function Login(){

    return(
        
        <div className="text-white d-flex h-100 flex-column">
            <LoginForm/>
        </div>
    );
}