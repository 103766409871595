import React from "react";
import { useParams } from "react-router-dom";
import { useGetArtistByIdQuery } from "../api/apiSlice";
import GridWrapper from "../components/grid/gridWrapper";
import ImageWrapper from "../components/grid/imageWrapper";

export default function Artist(){
    const {id} = useParams();
    const {data} = useGetArtistByIdQuery(id!);

    if(data){
        return(
            <GridWrapper>
                <div className="row mt-3 mb-3">
                    <div className="col-lg border-end border-secondary border-opacity-50">
                        <ImageWrapper base64={data.artistImage}/>
                    </div>
                    <div className="col-lg">
                        <div className="border-bottom border-secondary border-opacity-50">
                            <div className="fs-2">{data.artistName}</div>
                            <div className="text-secondary">{data.artistLocation}</div>
                        </div>
                        <div className="fs-5 text-milk mt-4">{data.artistDiscription}</div>
                    </div>
                </div>
            </GridWrapper>
        );
    }

    return(<div>Loading...</div>);
}