import React from "react";
import ColSearch from "./colSearch";
import ColLoginButton from "./colLoginButton";
import ColBrandIcon from "./colBrandIcon";
import ColNavItemLinks from "./colNavItemLinks";
import SideMenu from "./sideMenu";
import { useAppSelector } from "../../hooks/storeHooks";


export default function Header(){
    const userInfo = useAppSelector(state => state.user);

    return(
        <header>
            <nav className="navbar navbar-dark navbar-expand bg-black border-bottom border-secondary border-opacity-50" data-bs-theme="dark">
                <SideMenu/>
                <div className="container-fluid d-flex">
                    <ColNavItemLinks />
                    <ColBrandIcon />
                    <div className="col-lg-5 d-flex justify-content-center">
                        <ColSearch />
                        {
                            userInfo.isAuthenticated
                            ? <div className="col d-flex justify-content-end">
                                <button type="button" className="btn btn-warning">ВЫХОД</button>
                              </div>
                            : <ColLoginButton />
                        }
                    </div>
                </div>
            </nav>
        </header>
    );
}
               