import React from "react";
import { IArtistGroup } from "../../../models/grid/artists/iArtistGroup";
import { Link } from "react-router-dom";

interface Props{
    dictionary: IArtistGroup[]
}

export default function ArtistsListColumn({dictionary}: Props){


    return (
        <div className="col-lg text-white text-center fs-5">
            {
                dictionary.map((x, index) => {
                    if(x.value.length == 0)
                        return <div className="col" key={index}></div>

                    return(
                        <div className="col" key={index}>
                            <div className="col fs-4 border-bottom border-secondary border-opacity-50">{x.key}</div>
                            {
                                x.value.map((x) => 
                                    <div className="col border-bottom border-secondary border-opacity-50" key={x.id}>
                                        <Link className="link-secondary text-decoration-none" to={x.artistLink}>{x.artistName}</Link>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}