import React from "react";
import { useNavigate } from "react-router-dom";

interface Props{
    width?: number,
    marginBottom?: number,
    isInSideMenu?: boolean,
    onClickEvent?: () => void
}
export default function ColLoginButton({ width, marginBottom, isInSideMenu, onClickEvent }: Props){

    const widthClass: string = width ? ' w-' + width : ''; 
    const marginBottomClass: string = marginBottom ? ' mb-' + marginBottom : ''; 
    const disappearOnSmallScrean = isInSideMenu ? '' : 'd-none d-sm-block ';

    const navigate = useNavigate();
    const becomeMemberClickHandler = () =>{
        if(onClickEvent !== undefined){
            onClickEvent();
        }
        navigate("/become-member");
    }

    return(
        <div className="col d-flex justify-content-end">
            <button type="button" className={disappearOnSmallScrean + 'btn btn-warning' + widthClass + marginBottomClass} onClick={becomeMemberClickHandler}>СТАТЬ УЧАСТНИКОМ</button>
        </div>
    );
}