import React from "react";

interface Props{
    base64: string
}

export default function ImageWrapper({ base64 }: Props){

    return(
        <div className="d-flex flex-fill">
            <img src={base64} className="img-fluid"/>
        </div>
    )
}