import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IArtistRow } from "../../models/grid/artists/iArtistRow";
import { IArtistColumn } from "../../models/grid/artists/iArtistColumn";


interface userInitialState{
    rows: IArtistRow[],
    defaultColumn: IArtistColumn
}

const initialState: userInitialState = {
    rows: [],
    defaultColumn: {
        artistImage: '',
        artistLink: '',
        location: '',
        artistName: 'no name',
        fill: true,
        id: 0,
        order: 0,
        size: 4,
        artistProfileId: 0
    }
}

const artistsGridSlice = createSlice({
    name: 'artistsGrid',
    initialState: initialState,
    reducers: {
        setDraftArtistsGridRows(state, action: PayloadAction<IArtistRow[]>){
            state.rows = action.payload;
        },
        shrinkDraftArtistGridRowColumns(state, action: PayloadAction<{rowOrder: number, columns: IArtistColumn[]}>){
            let rowToChangeIndex = state.rows.findIndex(x => x.order == action.payload.rowOrder);
            state.rows[rowToChangeIndex].columns = action.payload.columns;
        },
        increaseDraftArtistGridRowColumns(state, action: PayloadAction<{rowOrder: number, increaseNumber:number}>){
            let rowToChangeIndex = state.rows.findIndex(x => x.order == action.payload.rowOrder);
            let columnsLength = state.rows[rowToChangeIndex].columns.length;
            if(action.payload.increaseNumber - columnsLength == 1){
                let defaultColumn = {...state.defaultColumn};
                defaultColumn.order = columnsLength + 1;
                state.rows[rowToChangeIndex].columns = [...state.rows[rowToChangeIndex].columns, defaultColumn];
            }
            if(action.payload.increaseNumber - columnsLength == 2){
                let defaultColumn = {...state.defaultColumn};
                defaultColumn.order = columnsLength + 1;
                let defaultColumn2 = {...state.defaultColumn};
                defaultColumn2.order = defaultColumn.order + 1;
                state.rows[rowToChangeIndex].columns = [...state.rows[rowToChangeIndex].columns, defaultColumn, defaultColumn2];
            }
        },
        sizeChangeDraftArtistGridColumn(state, action: PayloadAction<{rowOrder: number, columnOrder: number, size: number}>){
            let rowToChangeIndex = state.rows.findIndex(x => x.order == action.payload.rowOrder);
            let columnToChangeIndex = state.rows[rowToChangeIndex].columns.findIndex(x => x.order == action.payload.columnOrder);
            state.rows[rowToChangeIndex].columns[columnToChangeIndex].size = action.payload.size;
        },
        fillChangeDraftArtistGridColumn(state, action: PayloadAction<{rowOrder: number, columnOrder: number, fill: boolean}>){
            let rowToChangeIndex = state.rows.findIndex(x => x.order == action.payload.rowOrder);
            let columnToChangeIndex = state.rows[rowToChangeIndex].columns.findIndex(x => x.order == action.payload.columnOrder);
            state.rows[rowToChangeIndex].columns[columnToChangeIndex].fill = action.payload.fill;
        },
        addNewRowToDraftArtistGrid(state){
            let defaultColumn = {...state.defaultColumn};
            defaultColumn.order = 1;
            let maxOrder = 0;
            state.rows.forEach(x => {if (x.order > maxOrder) maxOrder = x.order});

            let newRow: IArtistRow = {
                columns: [defaultColumn],
                id: 0,
                order: maxOrder + 1
            }; 

            state.rows = [...state.rows, newRow];
        },
        deleteDraftArtistGridRow(state, action: PayloadAction<{rowOrder: number}>){
            state.rows = [...state.rows.filter(x => x.order != action.payload.rowOrder)];
        },
        updateDraftArtistGridColumn(state, action: PayloadAction<{rowOrder: number, columnOrder: number, newColumnState: IArtistColumn}>){
            let rowToUpdateIndex = state.rows.findIndex(x => x.order == action.payload.rowOrder);
            let columnToUpdateIndex = state.rows[rowToUpdateIndex].columns.findIndex(x => x.order == action.payload.columnOrder);
            state.rows[rowToUpdateIndex].columns[columnToUpdateIndex] = action.payload.newColumnState;
        }
    }
})

export default artistsGridSlice.reducer;
export const { 
    setDraftArtistsGridRows,
    shrinkDraftArtistGridRowColumns,
    increaseDraftArtistGridRowColumns,
    sizeChangeDraftArtistGridColumn,
    fillChangeDraftArtistGridColumn,
    addNewRowToDraftArtistGrid,
    deleteDraftArtistGridRow,
    updateDraftArtistGridColumn
} = artistsGridSlice.actions;