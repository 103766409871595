import React from 'react';
import Home from './pages/home';
import Login from './pages/login';
import Layout from './pages/layout';
import Artists from './pages/artists';
import BecomeMember from './pages/becomeMember';
import Videos from './pages/videos';
import Events from './pages/events';
import Artist from './pages/artist';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about';


export default function Router(){

    return(
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<Home/>} />
                <Route path='home' element={<Home/>} />
                <Route path='artists' element={<Artists/>} />
                <Route path='artists/:id' element={<Artist /> } />
                <Route path='login' element={<Login />} />
                <Route path='become-member' element={<BecomeMember />} />
                <Route path='videos' element={<Videos />} />
                <Route path='about' element={<About />} />
                <Route path='events' element={<Events /> } />
            </Route>
        </Routes>
    )
}