import React, { useEffect } from "react";
import Header from "../components/navigation/header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/navigation/footer";
import { useGetUserInfoQuery } from "../api/apiSlice";
import { useAppDispatch } from "../hooks/storeHooks";
import { setUserInfo } from "../store/slices/userSlice";
import background from '../images/background.jpg';
import ImageWrapper from "../components/grid/imageWrapper";

export default function Layout(){

    const { data } = useGetUserInfoQuery();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const backgroundStyle: React.CSSProperties | undefined = {
        background: `url(${background})`,
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        MozBackgroundSize: 'cover'
    }

    const backgroundStyleHandler = (): React.CSSProperties | undefined => {
        switch (location.pathname) {
            case '/events':
                return backgroundStyle;
            case '/about':
                return backgroundStyle;
            default:
                return {} as React.CSSProperties | undefined;
        }
    }

    useEffect(() => {
        if(data){
            dispatch(setUserInfo(data));
        }
    }, [data])

    return(
        <div className="d-flex flex-column overflow-auto vh-100 bg-black">
            <Header/>
            {
                (location.pathname == '/home' || location.pathname == '/') &&
                <img src={background} className="img-fluid" />
            }
            <main className="flex-fill justify-content-center">
                <div className="h-100" style={backgroundStyleHandler()}>
                    <div className="container-fluid h-100">
                        <div className="me-5 ms-5 h-100">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}