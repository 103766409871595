import React, { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { IMemberInput } from "../../models/iMemberInput";
import { usePostMemberFormMutation } from "../../api/apiSlice";
import { useNavigate } from "react-router-dom";


export default function BecomeMemberForm(){

    const { register, handleSubmit, reset, formState: {isSubmitSuccessful, errors}, control } = useForm<IMemberInput>({
        defaultValues: {
            id: 0,
            fullName: "",
            links: [{id: 0, name: '', link: ''}]
        }
    });
    const { fields, append, remove } = useFieldArray({
        name: 'links',
        control
    });

    const [postMemberForm, {isError, error, isSuccess} ] = usePostMemberFormMutation();
    const submit: SubmitHandler<IMemberInput> = data =>{
        data.phone = '+7' + data.phone;
        data.fullName = data.surnameName + ' ' + data.firstName[0].toUpperCase() + '.' + data.lastName[0].toUpperCase() + '.';
        postMemberForm(data);
    }

    const navigate = useNavigate();

    useEffect(() => {
        if(isSubmitSuccessful && isSuccess){
            reset({
                email: "",
                firstName: "",
                fullName: "",
                id: 0,
                lastName: "",
                phone: "",
                surnameName: "",
                links: [{id: 0, name: '', link: ''}]
            });
            navigate("/home");
        }
    }, [isSubmitSuccessful, isSuccess]);
    
    return(
        <div className="row justify-content-center mb-3 h-100">
            <form className="col-md-9 col-lg-8" onSubmit={handleSubmit(submit)}>
                <div className="h2 text-center">СТАТЬ УЧАСТНИКОМ</div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" placeholder="ИМЯ" { ...register("firstName", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.firstName?.message) && <div className="text-danger">{errors?.firstName?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" placeholder="ФАМИЛЯ" { ...register("surnameName", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.surnameName?.message) && <div className="text-danger">{errors?.surnameName?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" placeholder="ОТЧЕСТВО" { ...register("lastName", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.lastName?.message) && <div className="text-danger">{errors?.lastName?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <div className="input-group">
                        <span className="input-group-text bg-dark text-bg-dark">+7</span>
                        <input className="form-control form-control-dark text-bg-dark" { 
                            ...register("phone", { 
                                required: 'обязательное поле к заполнению', 
                                minLength: {value: 10, message: 'длина номера должна быть 10 цифр'}, 
                                maxLength: {value: 10, message: 'длина номера должна быть 10 цифр'},
                                pattern: {value: /^[0-9]+$/, message: 'формат: цифры от 0-9!'}
                            }) 
                        }/>
                    </div>
                    {
                        (errors?.phone?.message) && <div className="text-danger">{errors?.phone?.message}</div>
                    }
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" placeholder="ПОЧТА" { ...register("email", { required: 'обязательное поле к заполнению' }) }/>
                    {
                        (errors?.email?.message) && <div className="text-danger">{errors?.email?.message}</div>
                    }
                </div>
                <div className="mt-3 text-start h6">
                    Добавить ссылку:
                </div>
                <div className="mt-3">
                    {
                        fields.map((field, index) => (
                            <div key={index} className="mt-2">
                                <div className="d-flex">
                                    <input className="me-1 form-control form-control-dark text-bg-dark" placeholder='описание' {...register(`links.${index}.name`, { required: 'оба поля должны быть заполнены' })}/> 
                                    <input className="ms-1 form-control form-control-dark text-bg-dark" placeholder="ссылка" {...register(`links.${index}.link`, { required: 'оба поля должны быть заполнены' })}/>
                                    <button type="button" className="ms-2 btn-close btn-close-white h-auto" onClick={() => { remove(index) }}></button>
                                </div>
                                {
                                    (errors?.links) && <div className="text-danger">{errors.links[index]?.name?.message ?? errors.links[index]?.link?.message}</div>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="mt-3 d-flex justify-content-center">
                    <button type="button" className="btn btn-dark fs-5 align-middle" onClick={() => { append({id: 0, name: '', link: ''}) }}>+</button>
                </div>
                <div className="mt-3">
                    <input className="form-control form-control-dark text-bg-dark" type="submit"/>
                </div>
            </form>
        </div>
    );
}