import React from "react";
import { IVideoColumn } from "../../models/grid/videos/iVideoColumn";

interface Props{
    column: IVideoColumn
}

export default function ColVideo({ column }: Props){

    const whiteSpace = " ";

    const colSizeClass = column?.size ? "col-lg-" + column.size : "col";
    const flexFillClass = column.fill ? "flex-fill" : "";

    return(
        <div className={colSizeClass + whiteSpace + flexFillClass + " mb-3"}>
            <div className="h-100 d-flex flex-column border border-secondary border-opacity-50">
                <iframe
                    className="video ps-2 pe-2 pt-2"
                    src={column.videoLink}
                    // allow="accelerometer; encrypted-media; gyroscope;"
                    allowFullScreen
                ></iframe>
                <div className="mt-4 ps-2 pe-2 pb-2">
                    {
                        column.relatedArtists.map((artist, index) => (
                                <span key={index} className="fs-6 fw-lighter">
                                    {artist.artistName.split(' ')[0] + (column.relatedArtists.length - 1 === index ? '' : ', ')}
                                </span>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
}