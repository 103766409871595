import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IArtistListItem } from "../../models/iArtistListItem";
import { IArtistGroup } from "../../models/grid/artists/iArtistGroup";


interface artistsListState{
    list: IArtistListItem[]
}

const initialState: artistsListState = {
    list: []
}

const artistsListSlice = createSlice({
    name: 'artistsList',
    initialState: initialState,
    reducers: {
        setArtistsList(state, action: PayloadAction<IArtistGroup[]>){
            let list: IArtistListItem[] = [];
            for(let group of action.payload){
                for(let item of group.value){
                    list.push({name: item.artistName, id: +(item.artistLink.split('/').pop()?? 0)})
                }
            }
            state.list = list;
        }
    }
})

export default artistsListSlice.reducer;
export const { setArtistsList } = artistsListSlice.actions;